/* eslint-disable no-underscore-dangle */

/**
 * Main application customers
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import ProductsActions from "redux-react/actions/productsActions";
import _lod from "lodash";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon } from "@mui/material";
import i18n from "i18n";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";

import FormDictionaryDialog from "components/Custom/FormDictionary";
import AddProductDialog from "pages/product/AddProductDialog";
import AddProfileDialog from "pages/profile/AddProfileDialog";

const ButtonNewLabel = ({ handleClick, namePage }) => {
	return (
		<MDButton
			style={{ height: "100%", marginRight: "0.75rem" }}
			variant="contained"
			color="info"
			onClick={handleClick}
		>
			<Icon>add</Icon>&nbsp;{i18n.t("FORMS.LABELS.add") + namePage}
		</MDButton>
	);
};

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function FormsPage({ route }) {
	const dispatch = useDispatch();
	const [addNewLabel, setAddNewLabel] = useState(false);
	const [addNewProduct, setAddNewProduct] = useState(false);
	const [addNewProfile, setAddNewProfile] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [empty, setEmpty] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const { profile, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Raw charts from collection "analytics" in DB
	const [rawCharts, setRawCharts] = useState({});
	// Raw charts builded from the back
	const [chartsData, setChartsData] = useState({});
	// Default assistant filters
	const [pageFilters, setPageFilters] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");

	const [selectedId, setSelectedId] = useState(null);
	const [reloadTable, setReloadTable] = useState(false);

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData() {
		return chartsData[route.route] ?? [];
	}
	/**
	 * FIRST STEP : Get raw charts from DB
	 */
	function getRawChartsFromDatabase() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};
		dispatch(ChartsActions.getCharts(route, onSuccess));
	}
	/**
	 * SECOND STEP : Build charts with the raw charts on the back
	 */
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setChartsData({ ...chartsData, [route.route]: res.charts });
			setChartsLoading(false);
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		let actualFilters = getLocalStorageBackValues(route.route, filters);
		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts[route.route],
				actualFilters,
				mandatoryFilters,
				onSuccess
			)
		);
	}
	/**
	 * FIRST STEP : Get raw charts :
	 * - On mount / when page change
	 * - On selected assistant change
	 */
	useEffect(() => {
		// if (!chartsData[page]) {
		setChartsLoading(true);
		getRawChartsFromDatabase();
		// }
	}, [route]);
	/**
	 * SECOND STEP : Build charts :
	 * - On filters change
	 * - On rawCharts change
	 */
	useEffect(() => {
		buildRawCharts();
	}, [filters, rawCharts]);
	/**
	 * Load filters on mount
	 */

	const actionEditHandle = (items, target) => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(false);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setSelectedId(items._id);
			setSelectedTarget(target);
			setRouteDict(route.form.routeDictionary);
		};
		dispatch(FormActions.getItemByID(items._id, target, route.form.routeDictionary, onSuccess));
	};

	const actionEditEmptyHandle = () => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(true);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setSelectedTarget(route.form.collectionDefault);
			setRouteDict(route.form.routeDictionary);
		};

		dispatch(
			FormActions.getItemEmpty(route.form.collectionDefault, route.form.routeDictionary, onSuccess)
		);
	};

	const actionAddProductHandle = () => {
		setAddNewProduct(true);
	};

	const actionAddProfileHandle = () => {
		setAddNewProfile(true);
	};

	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
		setSelectedTarget(target);
	};

	const closeDeleteHandle = () => {
		setConfirmDelete(false);
	};

	const deleteCustomer = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			if (route?.form?.collectionDefault === "assistant") {
				dispatch(ProductsActions.deleteProduct(selectedItem._id, onSuccess));
			} else {
				dispatch(FormActions.deleteItem(selectedItem._id, selectedTarget, onSuccess));
			}
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	const actionSaveOrCreateCustomer = (values, unique, callback, rolesProfileUser) => {
		const onSuccess = res => {
			let messageAction = empty ? `${i18n.t("FORMS.addEmpty")}` : `${i18n.t("FORMS.updateItem")}`;
			dispatch(
				display({
					message: `${route?.form?.pageLabel} ${messageAction}`,
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewLabel(false);
			callback(true);
		};

		let data = { values, target: selectedTarget, unique };
		if (empty) {
			data = { ...data, rolesProfileUser };
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};

	const actionCreateProduct = product => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addAssistant"),
					type: "success"
				})
			);
			setAddNewProduct(false);
			setReloadTable(!reloadTable);
		};
		dispatch(ProductsActions.addProduct(product, onSuccess));
	};

	const actionCreateProfile = profile => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addProfile"),
					type: "success"
				})
			);
			setAddNewProfile(false);
			setReloadTable(!reloadTable);
		};
		dispatch(FormActions.addProfile(profile, onSuccess));
	};

	const getDeleteLabel = () => {
		switch (selectedTarget) {
			case "user":
				return (
					<MDBox>
						{i18n.t("FORMS.LABELS.confirmDeleteUser")}
						{selectedItem?.userID || ""} ?
					</MDBox>
				);
			case "assistant":
				return (
					<MDBox>
						{i18n.t("FORMS.LABELS.confirmDeleteAssistant")}
						{selectedItem?.name || ""} ?
					</MDBox>
				);
			case "profile":
				return (
					<MDBox>
						{i18n.t("FORMS.LABELS.confirmDeleteProfil")}
						{selectedItem?.userID || ""} {i18n.t("FORMS.LABELS.confirmDeleteProfilFor")}
						{selectedItem?.nameAssistant || ""} ?
					</MDBox>
				);
			default:
				return <MDBox>{i18n.t("FORMS.LABELS.confirmDelete")}</MDBox>;
		}
	};

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<MDBox display="flex" alignItems="center">
								<ButtonNewLabel
									handleClick={() => {
										if (route?.form?.collectionDefault === "assistant") {
											actionAddProductHandle();
										} else if (route?.form?.collectionDefault === "profile") {
											actionAddProfileHandle();
										} else {
											actionEditEmptyHandle();
										}
									}}
									namePage={route?.form?.pageLabel ?? route.name}
								/>
								<MDBox display="flex">
									{parseFilters(route.route, pageFilters, filters, dispatch)}
								</MDBox>
							</MDBox>
						]}
					/>
				</MDBox>

				{addNewProduct && (
					<AddProductDialog
						open={addNewProduct}
						handleCloseDialog={() => setAddNewProduct(false)}
						handleSave={product => actionCreateProduct(product)}
					/>
				)}

				{addNewProfile && (
					<AddProfileDialog
						open={addNewProfile}
						handleCloseDialog={() => setAddNewProfile(false)}
						handleSave={profile => actionCreateProfile(profile)}
					/>
				)}

				{addNewLabel && !_lod.isEmpty(valuesForm) && (
					<FormDictionaryDialog
						open={addNewLabel}
						route={route}
						handleCloseDialog={() => setAddNewLabel(false)}
						handleSave={(values, unique, callback, rolesProfileUser) =>
							actionSaveOrCreateCustomer(values, unique, callback, rolesProfileUser)
						}
						valuesDictionary={valuesForm}
						formBuildDictionary={formBuild}
						contextDictionary={contextDictionary}
						PIaccess
						isEmpty={empty}
						target={selectedTarget}
						selectedId={selectedId}
						routeDict={routeDict}
						reloadTable={() => {
							setReloadTable(!reloadTable);
						}}
					/>
				)}
				{confirmDelete && (
					<Dialog open={confirmDelete} onClose={closeDeleteHandle}>
						<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
						<DialogContent>{getDeleteLabel()}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={closeDeleteHandle} variant="outlined" color="dark">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={deleteCustomer} color="info" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}
				{/*
				 * Pagined table
				 */}
				{getChartsData()
					.filter(chart => chart.type === "paginedList")
					.map((chart, index) => {
						return (
							<DefaultDataTable
								reloadTable={reloadTable}
								dictionary={chart.dictionary}
								form={route.form}
								list={chart}
								pagination={chart.pagination}
								canSearch
								key={index}
								table={chart.data}
								display={chart.request.attributesDisplay}
								actionEditHandle={items => actionEditHandle(items, chart.request.collection)}
								actionDeleteHandle={items => actionDeleteHandle(items, chart.request.collection)}
							/>
						);
					})}
			</DashboardLayout>
		);
}
