/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import ProfileActions from "redux-react/actions/profileActions";
import SettingsActions from "redux-react/actions/settingsActions";
import i18n from "i18n";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updatePermissions } from "redux-react/reducers/permissionsReducer";
import C from "../charts/steps/constants";
import IconAccordion from "../charts/steps/components/IconAccordion";
import DictionaryMenu from "../filters/DictionaryMenu";

const RolesBox = ({ type, name, code, rolesList = [], onChange }) => {
	const isInArray = arr => {
		return arr.includes(code);
	};

	return (
		<MenuItem onClick={() => onChange(code)}>
			<MDBox display="flex" alignItems="center" justifyContent="start">
				<Checkbox checked={isInArray(rolesList)} />
				<MDBox display="flex" flexDirection="column">
					<MDTypography variant="body">{name}</MDTypography>
					{/* <MDTypography variant="caption">({i18n.t(`SETTINGS.${type}`)})</MDTypography> */}
				</MDBox>
			</MDBox>
		</MenuItem>
	);
};

export default function EditPageDialog({
	open,
	handleCloseDialog,
	handleSave,
	page,
	filtersList,
	dictionary
}) {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();
	/* Page attributes */
	const [name, setName] = useState(null);
	const [icon, setIcon] = useState(null);
	const [filterCode, setFilterCode] = useState(null);
	// -> Roles that will be displayed in the list to be selected
	const [defaultRolesList, setDefaultRolesList] = useState([]);
	// -> Roles that will be selected by the user
	const [rolesList, setRolesList] = useState([]);

	const [collection, setCollection] = useState(Object.keys(dictionary));
	const [anchorEl, setAnchorEl] = useState(null);
	const [routeDictLabel, setRouteDictLabel] = useState("");

	const [form, setForm] = useState({
		active: true,
		collectionDefault: null,
		pageLabel: null,
		routeDictionary: null
	});

	const disabledForType = type => {
		switch (type) {
			case "route":
				if (!name) {
					return true;
				}
				break;
			case "form":
				if (!form.collectionDefault || form.collectionDefault === "none") {
					return true;
				}
				break;
			case "title":
				if (!name) {
					return true;
				}
				break;
			case "divider":
				return false;
			default:
				return false;
		}

		return false;
	};

	const isDisabled = disabledForType(page.type);

	/**
	 * Close the dialog
	 */
	function close() {
		handleCloseDialog();
	}
	/**
	 * Create the page permission
	 * format be like : "read:page_[type]_[name]"
	 * For dividers, need to create a random ID because they have no name
	 */
	function createPagePermission(type, routeName, list) {
		// If user didn't select any role, return null -> We dont want permission on this page
		if (!list || lod_.isEmpty(list)) return null;
		// If the page is a divider, create a random ID for it
		if (type === "divider") {
			let rdmID = Math.random().toString(36).substring(7);
			return `read:page_${type}_${rdmID}`;
		}
		// Else, return the permission
		return `read:page_${type}_${routeName}`;
	}
	/**
	 * Submit the form, when edit is finished
	 */
	function sumbit() {
		// Get the current page permission or create a new one
		let pagePermission = page?.permissions?.length
			? page.permissions[0]
			: createPagePermission(page.type, page.name, rolesList);

		// Update the roles by page permission with the new roles list
		dispatch(ProfileActions.updateRolesByPagePermission(pagePermission, rolesList, res => {}));

		/* Handler when getting new permissions */
		const getCustomPermissionsSuccess = res => {
			// Update store with the new permissions
			dispatch(updatePermissions(res.permissions));
		};

		// Get the new permissions list
		dispatch(ProfileActions.getCustomPermissions(profile.role, getCustomPermissionsSuccess));

		// Update new page object
		if (!rolesList || lod_.isEmpty(rolesList)) {
			pagePermission = [];
		} else {
			pagePermission = [pagePermission];
		}

		let copy = page;
		if (name) {
			copy.name = name;
		}
		copy.icon = icon;
		copy.filter = filterCode;
		copy.permissions = pagePermission;
		copy.form = form;
		delete copy.open;
		handleSave(copy);
		close(true);
	}
	/**
	 * Get the permissions of the page (if exists)
	 * and load all the roles that have this permission
	 */
	function loadRolesByPagePermission(page) {
		if (!page) return;

		let pagePermissions = page.permissions || [];

		let promises = [];
		let tempRolesList = [];

		for (let perm of pagePermissions) {
			promises.push(
				new Promise((resolve, reject) => {
					dispatch(
						ProfileActions.getRolesByPagePermission(perm, res => {
							let roles = res.roles.map(role => role.role);
							tempRolesList = [...tempRolesList, ...roles];
							resolve();
						})
					);
				})
			);
		}

		Promise.all(promises).then(() => {
			setRolesList(tempRolesList);
		});
	}
	/**
	 * Update the roles list when user select / deselect a role
	 */
	function updateRolesList(newRole) {
		if (rolesList.includes(newRole)) {
			setRolesList(rolesList.filter(role => role !== newRole));
		} else {
			setRolesList([...rolesList, newRole]);
		}
	}
	/**
	 * Load default roles list (from KM)
	 */
	function loadRoles() {
		dispatch(
			SettingsActions.getRoles(res => {
				// Sort by type
				res.roles.sort((a, b) => {
					if (a.type < b.type) return -1;
					if (a.type > b.type) return 1;
					return 0;
				});
				setDefaultRolesList(res.roles);
			})
		);
	}
	/**
	 * Load page attributes on start
	 */
	useEffect(() => {
		setName(page.name);
		setIcon(page.icon);
		let realCode = filtersList.find(filter => filter.name === page.filter);
		setFilterCode(realCode?.code ?? page.filter);

		setForm({
			active: page?.form?.active || false,
			collectionDefault: page?.form?.collectionDefault || null,
			pageLabel: page?.form?.pageLabel || null,
			routeDictionary: page?.form?.routeDictionary || ""
		});

		let route = page?.form?.routeDictionary || "";
		let itemLabel = lod_.get(dictionary, route)?.label?.fr;

		setRouteDictLabel(itemLabel);

		loadRolesByPagePermission(page);
	}, [page]);

	const updateItem = item => {
		let route = item.replaceAll(".", ".items.");
		setForm({ ...form, routeDictionary: route });
		let itemLabel = lod_.get(dictionary, route)?.label?.fr;
		setAnchorEl(null);
		setRouteDictLabel(itemLabel);
	};

	/**
	 * Load roles list on start
	 */
	useEffect(() => {
		loadRoles();
	}, []);

	return (
		<Dialog fullWidth maxWidth="lg" open={open} onClose={() => close()}>
			<MDBox display="flex">
				<MDBox flex="2">
					<DialogTitle>{i18n.t("SETTINGS.PAGES.editPage")}</DialogTitle>
					<DialogContent>
						{page.type === "divider" && (
							<MDTypography variant="body2">
								{i18n.t("SETTINGS.PAGES.noParamsForRoute")}
							</MDTypography>
						)}
						{page.type !== "divider" && (
							<MDBox mt={1}>
								<MDInput
									className="dialogInput"
									label={i18n.t("SETTINGS.PAGES.name")}
									value={name}
									onChange={e => setName(e.target.value)}
								/>
							</MDBox>
						)}
						{page.type === "route" && (
							<>
								<MDBox display="flex" alignItems="flex-start " flexDirection="column">
									<MDBox mt={1}>
										{/* Icon choice */}
										<IconAccordion
											open
											forceOpen
											title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
											content={C.PAGE_ICONS_LIST.map((ic, index) => {
												return (
													<IconButton
														key={index}
														onClick={() => {
															setIcon(ic);
														}}
													>
														<Icon color={icon === ic ? "info" : ""} fontSize="large">
															{ic}
														</Icon>
													</IconButton>
												);
											})}
											actions={
												<MDButton onClick={() => setIcon(null)}>
													<Icon>close</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
												</MDButton>
											}
										/>
									</MDBox>
								</MDBox>

								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.filter")}</MDTypography>
								</MDBox>

								<MDBox mt={1}>
									<FormControl fullWidth>
										<InputLabel id="select-type">{i18n.t("SETTINGS.PAGES.filter")}</InputLabel>
										<Select
											labelId="select-type"
											value={filterCode || "none"}
											label={i18n.t("SETTINGS.PAGES.filter")}
											onChange={e => {
												if (e.target.value === "none") setFilterCode(null);
												else setFilterCode(e.target.value);
											}}
										>
											<MenuItem value="none">{i18n.t("SETTINGS.PAGES.none")}</MenuItem>
											{filtersList.map(filter => {
												return <MenuItem value={filter.code}>{filter.name}</MenuItem>;
											})}
										</Select>
									</FormControl>
								</MDBox>

								{page.form && (
									<>
										<MDBox mt={2}>
											<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.form")}</MDTypography>
										</MDBox>

										<MDBox mt={1}>
											<FormControl fullWidth>
												<InputLabel id="select-label">Collection</InputLabel>
												<Select
													labelId="select-collection-form"
													value={form.collectionDefault || "none"}
													label="Collection"
													onChange={e => {
														setForm({
															...form,
															collectionDefault: e.target.value
														});
													}}
												>
													<MenuItem value="none">Aucune</MenuItem>
													{collection &&
														collection.map(filter => {
															return <MenuItem value={filter}>{filter}</MenuItem>;
														})}
												</Select>
											</FormControl>
										</MDBox>

										<MDBox mt={2}>
											<MDInput
												value={form.pageLabel}
												className="dialogInput"
												label="Label de page"
												onChange={e =>
													setForm({
														...form,
														pageLabel: e.target.value
													})
												}
											/>
										</MDBox>

										<MDBox mt={2}>
											<MDTypography variant="h6">Objet source</MDTypography>
										</MDBox>
										<MDBox mt={2}>
											<MDBox display="flex" alignItems="center">
												<MDButton
													variant="gradient"
													color="info"
													onClick={e => setAnchorEl(e.target)}
												>
													{i18n.t("SETTINGS.add")}
												</MDButton>
												<DictionaryMenu
													levelLayer
													dictionary={dictionary}
													anchorEl={anchorEl}
													handleInsertText={updateItem}
													handleClose={() => setAnchorEl(null)}
												/>
												<MDBox display="flex" ml={1}>
													<MDTypography variant="body2">
														{routeDictLabel ?? "Aucune selection"}
													</MDTypography>
												</MDBox>
											</MDBox>
										</MDBox>
									</>
								)}
							</>
						)}
					</DialogContent>
					<DialogActions>
						<MDButton variant="outlined" color="dark" onClick={() => close()}>
							{i18n.t("SETTINGS.cancel")}
						</MDButton>
						<MDButton
							disabled={isDisabled && page.type !== "divider"}
							variant="contained"
							color="dark"
							onClick={sumbit}
						>
							{i18n.t("SETTINGS.edit")}
						</MDButton>
					</DialogActions>
				</MDBox>
				<MDBox ml={2} pr={4} flex="1">
					<DialogTitle>{i18n.t("SETTINGS.PAGES.visibleBy")}</DialogTitle>
					<DialogContent>
						{defaultRolesList &&
							defaultRolesList.map((perm, index) => {
								return (
									<RolesBox
										key={index}
										type={perm.type}
										name={perm.name}
										code={perm.code}
										rolesList={rolesList}
										onChange={perm => {
											updateRolesList(perm);
										}}
									/>
								);
							})}
					</DialogContent>
				</MDBox>
			</MDBox>
		</Dialog>
	);
}
