/* eslint-disable import/order */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18n from "i18n";
import LogicManagerLine from "./LogicManagerLine";
import C from "./constants";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { useState } from "react";

const LogicManager = ({
	lineArray,
	handleRemoveLine,
	onChangeValue,
	dictionary,
	handleAddFilter
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	// Get all possibles methods for a given type
	function getMethod(type) {
		switch (type) {
			case "string":
				return C.COMP_STRING;
			case "number":
			case "boolean":
				return C.COMP_NUMBER;
			case "code":
				return C.COMP_CODE;
			case "datetime":
			case "timestamp":
				return C.COMP_DATE;
			default:
				return [];
		}
	}

	return (
		<MDBox bgColor="white" borderRadius="lg" p={2}>
			<Table>
				<TableHead
					style={{
						display: "contents"
					}}
				>
					<TableRow>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
						</TableCell>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.method")}
						</TableCell>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lineArray.length === 0 && (
						<TableRow>
							<TableCell colSpan={5} align="center">
								{i18n.t("SETTINGS.CHARTS.COMPUTE.noFilters")}
							</TableCell>
						</TableRow>
					)}
					{lineArray.map((filter, index) => {
						return (
							<LogicManagerLine
								logicCode={filter.logicCode}
								dictionary={dictionary}
								name={filter.name}
								value={filter.value}
								filter={filter.filter}
								methods={getMethod(filter.filter.type)}
								key={index}
								handleRemoveFilter={handleRemoveLine}
								onChangeValue={onChangeValue}
							/>
						);
					})}
					<MDBox m={1} display="flex">
						<MDButton
							fontSize="medium"
							color="light"
							variant="gradient"
							onClick={e => setAnchorEl(e.target)}
						>
							{i18n.t("SETTINGS.add")}
						</MDButton>
					</MDBox>
					<DictionaryMenu
						placement="right"
						dictionary={dictionary}
						anchorEl={anchorEl}
						handleInsertText={e => {
							setAnchorEl(null);
							handleAddFilter(e);
						}}
						handleClose={() => setAnchorEl(null)}
					/>
				</TableBody>
			</Table>
		</MDBox>
	);
};

export default LogicManager;
