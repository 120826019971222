/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function configs(labels, datasets) {
	datasets.map(dataset => {
		dataset.backgroundColor = dataset.color;
		dataset.borderWidth = 0;
		delete dataset.maxBarThickness;
	});

	return {
		data: {
			labels,
			datasets: [...datasets]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: "left"
				}
			},
			scales: {
				x: {
					gridLines: {
						display: false
					},
					barPercentage: 1,
					categoryPercentage: 1,
					stacked: true,
					ticks: {
						display: true,
						color: "#9ca2b7",
						font: {
							size: 11,
							style: "normal"
						}
					}
				},
				y: {
					stacked: true
				}
			}
		}
	};
}

export default configs;
