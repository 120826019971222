import { useState, useEffect } from "react";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";
import MiniInfoCard from "components/Advanced/Cards/InfoCards/MiniInfoCard";
import ProfileInfoCard from "components/Advanced/Cards/InfoCards/ProfileInfoCard";
import DefaultInfoCard from "components/Advanced/Cards/InfoCards/DefaultInfoCard";
import { Avatar } from "@mui/material";

/**
 * Step 2: Settings profile
 */
const ProfileReview = ({ profile, validStep }) => {
	useEffect(() => {
		if (profile?.userID && profile?.assistantID && profile?.role) {
			validStep();
		}
	}, []);

	let avatar = false;

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				<MDBox display="flex">
					<MDBox flex="1">
						<MDTypography variant="h3" color="dark">
							{i18n.t("SETTINGS.PRODUCTS.reviewProduct")}
						</MDTypography>

						<DefaultInfoCard
							icon={
								<Avatar
									style={{ width: 100, height: 100, objectFit: "cover" }}
									alt="Profil"
									src={profile.picture}
								/>
							}
							title={
								<MDBox display="flex" justifyContent="center">
									<MDTypography variant="h5" color="dark">
										{i18n.t("SETTINGS.PROFILE.userID")} :&nbsp;
									</MDTypography>
									<MDTypography variant="h6" color="text">
										{i18n.t(profile.userID)}
									</MDTypography>
								</MDBox>
							}
							description={
								<MDBox display="flex" justifyContent="center" alignItems="center">
									<MDTypography variant="h5" color="dark">
										{i18n.t("SETTINGS.PROFILE.roleUser")} :&nbsp;
									</MDTypography>
									<MDTypography variant="h6" color="text">
										{i18n.t(profile.role)}
									</MDTypography>
								</MDBox>
							}
							value={
								<MDBox display="flex" justifyContent="center">
									<MDTypography variant="h5" color="dark">
										{i18n.t("SETTINGS.PRODUCTS.nameAssistant")} :&nbsp;
									</MDTypography>
									<MDTypography variant="h6" color="text">
										{i18n.t(profile.nameAssistant)}
									</MDTypography>
								</MDBox>
							}
							avatar={avatar}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default ProfileReview;
