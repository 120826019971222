import { useState, useEffect } from "react";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";
import MiniInfoCard from "components/Advanced/Cards/InfoCards/MiniInfoCard";
import DefaultInfoCard from "components/Advanced/Cards/InfoCards/DefaultInfoCard";

/**
 * Step 2: Settings product
 */
const ProductreviewStep = ({ product, handleSettingsProduct, validStep }) => {
	// Handle change settings product
	const handleChange = (path, value) => {
		let copyProduct = lod_.cloneDeep(product);
		copyProduct = lod_.set(copyProduct, path, value);
		handleSettingsProduct(copyProduct);
		validStep();
	};

	useEffect(() => {
		if (product?.parameters?.name && product?.parameters?.description) {
			validStep();
		}
	}, []);

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				<MDBox display="flex">
					<MDBox flex="1">
						<MDTypography variant="h3" color="dark">
							{i18n.t("SETTINGS.PRODUCTS.reviewProduct")}
						</MDTypography>

						<DefaultInfoCard
							icon="visibility"
							title={
								<MDTypography variant="h3" color="dark">
									{i18n.t(product.parameters.name)}
								</MDTypography>
							}
							description={i18n.t(product.parameters.description)}
							value={i18n.t(product.name)}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default ProductreviewStep;
