import { setPermissions } from "redux-react/reducers/permissionsReducer";
import { updateMenu, selectProfile } from "redux-react/reducers/profileReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	selectProfile: (payload, assistants) => {
		return dispatch => {
			dispatch(selectProfile({ profile: payload.profile, assistants }));
			dispatch(setPermissions(payload.permissions));
		};
	},
	// For a roles list, add a new permission for a given product and assistant
	createCustomPermission: (rolesList, product, permission, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/permissions/${product}`,
					data: { roles: rolesList, permission },
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	// When user change permissions for roles for a page
	updateRolesByPagePermission: (pagePermission, roles, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/roles/${pagePermission}`,
					data: {
						roles
					},
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	// Get all roles who have this page permission
	getRolesByPagePermission: (pagePermission, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/roles/${pagePermission}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	// Get all permissions for a given role
	getCustomPermissions: (role, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/permissions/custom/${role}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	updateMenu: (accountID, success) => {
		const onSuccess = res => {
			if (success) success(res.menu);
			return dispatch => {
				dispatch(updateMenu({ menu: res.menu }));
			};
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/menu`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
