import { useState, useEffect } from "react";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";

/**
 * Step 2: Settings product
 */
const ProductSettingsStep = ({ product, handleSettingsProduct, validStep }) => {
	// Handle change settings product
	const handleChange = (path, value) => {
		let copyProduct = lod_.cloneDeep(product);
		copyProduct = lod_.set(copyProduct, path, value);
		handleSettingsProduct(copyProduct);

		validStep(
			!lod_.isNil(copyProduct?.parameters?.name) &&
				!lod_.isEqual(copyProduct?.parameters?.name, "") &&
				!lod_.isNil(copyProduct?.parameters?.description) &&
				!lod_.isEqual(copyProduct?.parameters?.description, "")
		);
	};

	useEffect(() => {
		if (product?.parameters?.name && product?.parameters?.description) {
			validStep();
		}
	}, []);

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				<MDBox display="flex">
					<MDBox flex="1">
						<MDTypography variant="h3" color="dark">
							{i18n.t("SETTINGS.PRODUCTS.product") + " : " + i18n.t(product.name)}
						</MDTypography>
					</MDBox>
				</MDBox>
				<MDBox mt={2} width="50%">
					<MDBox mt={2}>
						<MDInput
							name="name"
							className="dialogInput"
							label={i18n.t("SETTINGS.PRODUCTS.nameAssistant")}
							value={product.parameters.name || ""}
							onChange={e => handleChange("parameters.name", e.target.value)}
						/>
					</MDBox>
					<MDBox mt={2}>
						<MDInput
							name="desc"
							className="dialogInput"
							label={i18n.t("SETTINGS.PRODUCTS.descAssistant")}
							value={product.parameters.description || ""}
							onChange={e => handleChange("parameters.description", e.target.value)}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default ProductSettingsStep;
