/* eslint-disable consistent-return */
import MDAlert from "components/Basics/MDAlert";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useDispatch, useSelector } from "react-redux";

/**
 * #######################################################@
 *
 * SnackBar component
 *
 * Used to display a message in a snackbar by the store
 *
 * #######################################################@
 */
const { Snackbar, Alert, Icon, Fade, Slide } = require("@mui/material");
const { useState, useEffect } = require("react");
const { hide } = require("redux-react/reducers/snackBarReducer");

const SnackBarMessage = () => {
	const dispatch = useDispatch();
	const { snackBar, notifications } = useSelector(state => state);

	const notificationsList = useSelector(state => state.notifications.list || []);

	const handleClose = () => {
		dispatch(hide());
	};

	const [alertContent, setAlertContent] = useState("");

	const [showAlert, setShowAlert] = useState(false);

	const [oldLength, setOldLength] = useState(0);

	useEffect(() => {
		if (!notificationsList.length) {
			setOldLength(0);
			return;
		}

		if (notificationsList.length > (oldLength || 0)) {
			setOldLength(notificationsList.length);
			setAlertContent(notificationsList[notificationsList.length - 1].title);
			setShowAlert(true);

			const timer = setTimeout(() => {
				setShowAlert(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [notifications, dispatch]);

	return (
		<>
			{/*
			 * Notifications
			 */}
			<div className="notificationsContainer">
				<Slide in={showAlert} direction="down">
					<div>
						<MDAlert>
							<Icon fontSize="small">notifications</Icon>
							<MDTypography variant="body2" color="white">
								{alertContent ?? "Nouvelle notification !"}
							</MDTypography>
						</MDAlert>
					</div>
				</Slide>
			</div>
			{/*
			 * SnackBar
			 */}
			<Snackbar
				open={snackBar.open ?? false}
				autoHideDuration={snackBar.validation ? null : 4000}
				onClose={snackBar.validation ? null : handleClose}
			>
				<Alert
					severity={snackBar.type ?? "info"}
					style={{
						display: "flex",
						alignItems: "center"
					}}
				>
					<MDBox display="flex" alignItems="center">
						{snackBar.message ?? ""}
						{snackBar.validation ? snackBar.validation : null}
					</MDBox>
				</Alert>
			</Snackbar>
		</>
	);
};

export default SnackBarMessage;
