import { CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import i18n from "i18n";

import { useDispatch } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";

import MDBox from "components/Basics/MDBox";
import InformationCardAssistant from "../components/InformationCardAssistant";

/**
 * Step 1: Selected the profile type
 */
const ProfileChoiceStepAssistant = ({ profile, handleSelectAssistant, validStep }) => {
	const dispatch = useDispatch();

	// Handle select profile
	const selectProfile = assistant => {
		handleSelectAssistant(assistant.assistantID, assistant.codeOffering, assistant.name);
		validStep();
	};

	const [assistantList, setAssistantList] = useState([]);
	const [loading, setLoading] = useState(true);

	const [search, setSearch] = useState("");

	const filterList = list => {
		if (search.length === 0) {
			return list;
		}
		return list.filter(
			c =>
				c.name?.toLowerCase().includes(search.toLowerCase()) ||
				c.description?.toLowerCase().includes(search.toLowerCase())
		);
	};

	useEffect(() => {
		if (profile?.assistantID) {
			validStep();
		}
		const onSuccess = res => {
			setAssistantList(res.assistants);
			setLoading(false);
		};

		dispatch(FormActions.assistantsProfile("assistant", profile?.userID, onSuccess));
	}, []);

	if (loading) {
		return (
			<MDBox
				display="flex"
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%"
				}}
			>
				<CircularProgress color="info" size={50} />
			</MDBox>
		);
	}

	return (
		<MDBox>
			<MDInput
				label="Rechercher"
				fullWidth
				value={search}
				onChange={e => setSearch(e.target.value)}
			/>
			<MDBox display="flex" flexDirection="column" justifyContent="space-evenly">
				{assistantList?.length > 0 ? (
					<>
						{filterList(assistantList || [])
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((c, index) => {
								return (
									<InformationCardAssistant
										element={c}
										onClick={() => selectProfile(c)}
										selected={profile && profile.assistantID === c.assistantID}
									/>
								);
							})}
					</>
				) : (
					<MDBox style={{ display: "flex", justifyContent: "center" }}>
						<MDTypography variant="h5">{i18n.t("SETTINGS.PROFILE.noAssistant")}</MDTypography>
					</MDBox>
				)}
			</MDBox>
		</MDBox>
	);
};

export default ProfileChoiceStepAssistant;
