/**
 * PIKA: context
 */
import React, { createContext, useContext, useMemo } from "react";
import axios from "axios";
import i18n from "i18n";
import { useDispatch } from "react-redux";
import { setError } from "redux-react/reducers/errorReducer";
import { setPermissions } from "redux-react/reducers/permissionsReducer";
import { selectProfile } from "redux-react/reducers/profileReducer";
import { wsConnect } from "redux-react/actions/ws";
import { login } from "../redux-react/reducers/userReducers";

const fAibrikContext = createContext();

const endPoint = process.env.REACT_APP_AMBACK;

export const ProvideAuthFaibrik = props => {
	/**
	 * Check if the selected user has the given permission like 'write_answer'
	 * @param {string} perm
	 */
	const dispatch = useDispatch();

	const fAibrikStatus = {
		fetching: user => {
			if (!user) {
				return;
			}

			axios(endPoint + "/api/v1/accountConfig", {
				headers: {
					Authorization: `Bearer ${user.token}`
				},
				method: "GET"
			})
				.then(async responseData => {
					responseData = responseData.data.payload;
					// set i18n default language
					let language = responseData?.user?.language?.toLowerCase();
					if (language) {
						await i18n.changeLanguage(language);
					}
					dispatch(
						login({
							token: user.token,
							...responseData.user
						})
					);

					dispatch(
						wsConnect({
							user
						})
					);

					dispatch(setPermissions(responseData.permissions));

					dispatch(
						selectProfile({
							...responseData.menu
						})
					);
				})
				.catch(err => {
					dispatch(setError(err.response?.data?.error ?? "none"));
				});
		}
	};

	const provideValues = useMemo(() => {
		return {
			fAibrikStatus
		};
	});

	return <fAibrikContext.Provider value={provideValues} {...props} />;
};
export const useFAibrikContext = () => useContext(fAibrikContext);
