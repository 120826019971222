/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/**
 * #######################################################@
 *
 * Charts settings
 *
 * #######################################################@
 */
import "./style.css";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { Divider, Icon, IconButton, Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import SettingsActions from "redux-react/actions/settingsActions";
import { display, hide } from "redux-react/reducers/snackBarReducer";
import { resetFilters } from "redux-react/reducers/filtersReducers";
import { useNavigate } from "react-router-dom";
import lod_ from "lodash";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import MDBox from "components/Basics/MDBox";
import { parseFilters } from "components/Custom/Filters/filters";
import i18n from "i18n";
import EditChartDialog from "./EditChartDialog";
import AddChartDialog from "./AddChartDialog";

export default function SettingsCharts({ route }) {
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// Page Charts
	const [paginedList, setPaginedList] = useState(null);
	// Add new chart dialog state
	const [addNewChart, setAddNewChart] = useState(false);
	// Edit chart dialog state
	const [editChart, setEditChart] = useState({
		open: false
	});
	// Confirm dialog state
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});
	// Load charts from back
	// Load pagined lists
	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList[0]);
		};

		dispatch(ChartsActions.getPaginedList(route, onSuccess));
	}
	// Handle save new chart
	const handleSaveNewChart = chart => {
		const onSuccess = res => {
			let code = chart.code;
			let page = chart.display.pages[0];
			dispatch(
				display({
					type: "info",
					message: i18n.t("SETTINGS.CHARTS.SUCCESS.add"),
					validation: (
						<>
							<MDButton
								variant="contained"
								color="info"
								sx={{ ml: 1 }}
								onClick={() => {
									dispatch(hide());
									let anchorRef = `#${code}`;
									navigate(`/${page}${anchorRef}`);
								}}
							>
								{i18n.t("SETTINGS.see")}
							</MDButton>
							<MDButton
								sx={{ ml: 1 }}
								onClick={() => {
									dispatch(hide());
								}}
							>
								{i18n.t("SETTINGS.close")}
							</MDButton>
						</>
					)
				})
			);
			loadPaginedList();
		};
		dispatch(SettingsActions.newSetting("chart", chart, onSuccess));
	};
	// Handle delete chart
	const actionDeleteHandle = ({ values }) => {
		const handleConfirmDialog = () => {
			setConfirmDialog({
				open: false
			});
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.CHARTS.SUCCESS.delete"),
						type: "success"
					})
				);
				loadPaginedList();
			};
			dispatch(SettingsActions.deleteSetting("chart", { code: values.code }, onSuccess));
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} : ${i18n.t(`SETTINGS.CHARTS.confirmDelete`)} ${
				values["display.title"] ? `"${values["display.title"]}"` : ""
			}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} : ${i18n.t(
				`SETTINGS.CHARTS.confirmDelete`
			)} ${values["display.title"] ? `"${values["display.title"]}"` : ""} ?`,
			handleConfirm: handleConfirmDialog
		});
	};
	/* Get chart by code */
	const getChartByCode = code => {
		const onSuccess = res => {
			if (res.chart) {
				setEditChart({
					open: true,
					chart: res.chart
				});
			} else {
				dispatch(
					display({
						message: i18n.t("SETTINGS.CHARTS.ERROR.notFound"),
						type: "error"
					})
				);
			}
		};
		dispatch(ChartsActions.getChartByCode(code, onSuccess));
	};

	useEffect(() => {
		loadPaginedList();
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox display="flex">
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							color="info"
							onClick={() => setAddNewChart(true)}
						>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.CHARTS.add")}
						</MDButton>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<DefaultDataTable
					canSearch
					table={paginedList.data}
					display={paginedList.request.attributesDisplay}
					actions={[
						<IconButton
							customdisabled={({ values }) => {
								return values.type === "multiCollection";
							}}
							handleclick={({ values }) => {
								getChartByCode(values.code);
							}}
						>
							<Tooltip title={i18n.t("SETTINGS.edit")} placement="top">
								<Icon fontSize="medium">edit</Icon>
							</Tooltip>
						</IconButton>,
						<IconButton handleclick={actionDeleteHandle}>
							<Tooltip title={i18n.t("SETTINGS.delete")} placement="top">
								<Icon fontSize="medium">delete</Icon>
							</Tooltip>
						</IconButton>
					]}
				/>
			)}
			{paginedList && (
				<AddChartDialog
					collections={paginedList.collections}
					open={addNewChart}
					handleCloseDialog={() => setAddNewChart(false)}
					handleSave={handleSaveNewChart}
				/>
			)}
			<EditChartDialog
				open={editChart.open}
				handleCloseDialog={() => setEditChart({ open: false })}
				handleSave={chart => {
					delete chart._id;
					const onSuccess = res => {
						dispatch(
							display({
								message: i18n.t("SETTINGS.CHARTS.SUCCESS.edit"),
								type: "success"
							})
						);
						loadPaginedList();
					};
					dispatch(SettingsActions.editSetting("chart", chart, onSuccess));
				}}
				originalChart={editChart.chart}
			/>
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
		</DashboardLayout>
	);
}
