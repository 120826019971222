import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getItemByID: (_id, collection, route, onSuccess, accessPI = false, bypass = false) => {
		let data = {
			accessPI,
			collection,
			route,
			bypass
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getItemEmpty: (target, route, onSuccess) => {
		let data = {
			route
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/empty/${target}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	resetPassword: (email, onSuccess) => {
		let data = {
			email
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/resetPassword`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	blockUser: (id, auth0ID, block, onSuccess, onFailure = () => {}) => {
		let data = {
			id,
			auth0ID,
			block
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/blockUser`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	addItemEmpty: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/empty`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						onFailure(err);
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	updateItem: (_id, data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/form/update/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	deleteItem: (_id, collection, onSuccess) => {
		let data = { target: collection };
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/delete/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getProfileItem: (_id, collection, onSuccess) => {
		let data = {
			collection
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/getProfile/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},

	getRolesP: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/rolesP`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},

	getRolesAssistantP: (assistants, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/rolesP/product`,
					data: assistants,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getUnique: (valueUnique, onSuccess) => {
		let data = { value: valueUnique };
		return dispatch => {
			dispatch(
				api({
					method: "post",
					url: `/api/v1/form/unique/product`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	addProfile: (data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "post",
					url: `/api/v1/form/profile/add`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getAssistants: (collection, onSuccess) => {
		let data = {
			collection
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/assistants`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	assistantsProfile: (collection, userID, onSuccess) => {
		let data = {
			collection,
			userID
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/assistantsProfile`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getUsers: (collection, role, onSuccess) => {
		let data = {
			collection,
			role
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/users`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getCheckProfile: (collection, parmsData, onSuccess) => {
		let data = {
			collection,
			...parmsData
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/checkProfile`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	// Get items from collection
	getItemsFromCollection: (collection, data, onSuccess, cluster = {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/collection/${collection}`,
					data: { cluster, ...data },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error?.message ?? "none"));
					}
				})
			);
		};
	},
	getRegexLittleForm: (typeRegex = "email", onSuccess) => {
		let data = {
			typeRegex
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/litleForm/regex`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getListApis: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/litleForm/listApis`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	}
};

export default actions;
