/* eslint-disable no-extra-boolean-cast */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */

import {
	Autocomplete,
	Chip,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDDatePicker from "components/Basics/MDDatePicker";
import MDInput from "components/Basics/MDInput";
import MuiPhoneNumber from "mui-phone-number";
import lod_ from "lodash";
import i18n from "i18next";
import MDTypography from "components/Basics/MDTypography";
import FormActions from "redux-react/actions/formAction";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { regexMail } from "helpers/utilities";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";

let listOfApis = [];
let regexEmail = "";
/**
 * Specific component for delay input
 */
const DelayRow = ({ label, value, onChange }) => {
	const [days, setDays] = useState(Math.floor(value / (24 * 3600 * 1000)));
	const [hours, setHours] = useState(Math.floor((value % (24 * 3600 * 1000)) / (3600 * 1000)));
	const [minutes, setMinutes] = useState(Math.floor((value % (3600 * 1000)) / (60 * 1000)));
	const [seconds, setSeconds] = useState(Math.floor((value % (60 * 1000)) / 1000));

	useEffect(() => {
		let newValue =
			days * 24 * 3600 * 1000 + hours * 3600 * 1000 + minutes * 60 * 1000 + seconds * 1000;
		onChange(newValue);
	}, [days, hours, minutes, seconds]);

	return (
		<MDBox>
			<MDBox mr={1}>
				<MDTypography variant="body1" fontSize="small">
					{label}
				</MDTypography>
			</MDBox>
			<MDBox mt={1} display="flex" alignItems="center">
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.day")}
						value={days}
						onChange={e => setDays(e.target.value)}
						fullWidth
					/>
				</MDBox>
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.hour")}
						value={hours}
						onChange={e => setHours(e.target.value)}
						inputProps={{
							max: 23,
							min: 0
						}}
					/>
				</MDBox>
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.minute")}
						value={minutes}
						onChange={e => setMinutes(e.target.value)}
						inputProps={{
							max: 59,
							min: 0
						}}
					/>
				</MDBox>
				<MDBox flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.second")}
						value={seconds}
						onChange={e => setSeconds(e.target.value)}
						inputProps={{
							max: 59,
							min: 0
						}}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};
/**
 * Component for input
 */
const FormInput = ({ item, path, datas, handleChange, regexEmail }) => {
	const [viewInvalid, setViewInvalid] = useState("");
	const [viewInvalidEmail, setViewInvalidEmail] = useState("");
	const { user } = useSelector(state => state);

	let type = item.type;
	let label = item.label.fr;
	let codes = item.codes || [];
	let isArray = item.isArray || false;
	let whitelist = item.whitelist || [];
	let whitelistDynamic = item.whitelistDynamic || {};

	let value = lod_.get(datas, path);

	const [valueAuto, setValueAuto] = useState(value);
	const [optionAuto, setOptionAuto] = useState([]);

	async function loadSuggestions(searchText, searchFields, apiName, action, limit = 10) {
		let attribute = whitelistDynamic?.params?.attribute;

		let APIFind;
		let actionFind;
		if (apiName && action && !lod_.isEmpty(listOfApis)) {
			APIFind = listOfApis.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			return [];
		}

		let config = {
			method: actionFind?.method,
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${actionFind?.collection}/dynamic?search=${searchText}&page=1&limit=${limit}`,
			data: { filter: actionFind?.filter, searchFields, attribute },
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: null,
				"Content-Type": "application/json"
			}
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});
		return result?.results || [];
	}

	const ValueChange = async valueC => {
		setValueAuto(valueC);

		let result = await loadSuggestions(
			valueC,
			whitelistDynamic.params.searchFields || [],
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	};

	const onChange = value => {
		handleChange(path, value);
	};

	const getOptionLabel = option => {
		let labelAttribute = option[whitelistDynamic?.params?.attribute] || "";
		let label = whitelistDynamic.params?.searchFields
			? " - " + whitelistDynamic.params?.searchFields.map(key => option[key]).join(" - ")
			: "";
		label = labelAttribute + label;
		return label;
	};

	const onChangeDynamic = (event, newValue, option) => {
		if (option === "selectOption") {
			setValueAuto(newValue[whitelistDynamic.params.attribute]);
			onChange(newValue[whitelistDynamic.params.attribute]);
		} else if (option === "clear") {
			setValueAuto("");
			onChange("");
		}
	};

	const whiteListItem = () => {
		if (lod_.isArray(whitelist) && !lod_.isEmpty(whitelist)) {
			return (
				<MDBox mt={1}>
					<FormControl fullWidth>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || null}
							onChange={e => {
								onChange(e.target.value);
							}}
						>
							{whitelist.map((option, index) => (
								<MenuItem key={index} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBox>
			);
		} else if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			return (
				<Autocomplete
					id="auto"
					freeSolo
					options={optionAuto}
					onChange={onChangeDynamic}
					getOptionLabel={getOptionLabel}
					inputValue={valueAuto || ""}
					renderInput={params => (
						<MDInput {...params} label={label} onChange={e => ValueChange(e.target.value)} />
					)}
				/>
			);
		} else {
			return (
				<MDInput
					type={type}
					className="dialogInput"
					label={label}
					value={value}
					onChange={e =>
						type === "number" ? onChange(parseFloat(e.target.value)) : onChange(e.target.value)
					}
				/>
			);
		}
	};

	switch (type) {
		case "boolean":
			return (
				<MDBox
					mt={1}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					className="customSwitchContainer"
					onClick={e => {
						onChange(!Boolean(value));
					}}
				>
					<span>{label}</span>
					<MDBox>
						<Switch checked={Boolean(value)} />
					</MDBox>
				</MDBox>
			);
		case "phone":
			return (
				<MDBox mt={1}>
					<MuiPhoneNumber
						defaultCountry="fr"
						onChange={e => onChange(e)}
						type="tel"
						className="dialogInput"
						label={label}
						value={value}
						variant="outlined"
					/>
				</MDBox>
			);
		case "date":
		case "datetime":
			return (
				<MDBox mt={1}>
					<MDDatePicker
						type="date"
						label={label}
						value={value}
						onChange={e => onChange(e)}
						options={{
							time_24hr: true,
							dateFormat: "d M Y"
						}}
						input={{
							placeholder: `${label} `,
							style: { width: "100%" }
						}}
					/>
				</MDBox>
			);
		case "email":
			return (
				<MDBox mt={1}>
					{isArray ? (
						<Tooltip
							open={viewInvalid === path}
							title={viewInvalidEmail}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={lod_.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									const isValid = newValue.every(val => val === "" || regexMail(val, regexEmail));
									if (isValid) {
										if (lod_.isEmpty(whitelist)) {
											onChange(newValue);
										} else {
											const stringValuesWhite = newValue.filter(val => whitelist.includes(val));
											if (stringValuesWhite.length === newValue.length) {
												onChange(stringValuesWhite);
											} else {
												setViewInvalid(path);
												setViewInvalidEmail(i18n.t("FORMS.whitelistInvalidList"));

												setTimeout(() => {
													setViewInvalid("");
													setViewInvalidEmail("");
												}, 3000);
											}
										}
									} else {
										setViewInvalid(path);
										setViewInvalidEmail(i18n.t("FORMS.emailInvalidList"));
										setTimeout(() => {
											setViewInvalid("");
											setViewInvalidEmail("");
										}, 3000);
									}
								}}
								multiple
								id="tags-filled"
								options={whitelist}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip key={index} label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => <MDInput {...params} label={label} />}
							/>
						</Tooltip>
					) : (
						whiteListItem()
					)}
				</MDBox>
			);
		case "string":
			return (
				<MDBox mt={1}>
					{isArray ? (
						<Tooltip
							open={viewInvalid === path}
							title={i18n.t("FORMS.whitelistInvalidList")}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={lod_.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									if (lod_.isEmpty(whitelist)) {
										onChange(newValue);
									} else {
										const stringValuesWhite = newValue.filter(val => whitelist.includes(val));
										if (stringValuesWhite.length === newValue.length) {
											onChange(stringValuesWhite);
										} else {
											setViewInvalid(path);
											setTimeout(() => {
												setViewInvalid("");
											}, 3000);
										}
									}
								}}
								multiple
								id="tags-filled"
								options={whitelist}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip key={index} label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => <MDInput {...params} label={label} />}
							/>
						</Tooltip>
					) : (
						whiteListItem()
					)}
				</MDBox>
			);
		case "number":
			return (
				<MDBox mt={1}>
					{isArray ? (
						<Tooltip
							open={viewInvalid === path}
							title={i18n.t("FORMS.whitelistInvalidList")}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={lod_.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									if (lod_.isEmpty(whitelist)) {
										const numericValues = newValue.map(val => parseFloat(val));
										onChange(numericValues);
									} else {
										const numericValuesWhite = newValue.filter(val => whitelist.includes(val));
										if (numericValuesWhite.length === newValue.length) {
											onChange(numericValuesWhite);
										} else {
											setViewInvalid(path);
											setTimeout(() => {
												setViewInvalid("");
											}, 3000);
										}
									}
								}}
								multiple
								id="tags-filled"
								options={whitelist}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip key={index} label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => <MDInput {...params} type="number" label={label} />}
							/>
						</Tooltip>
					) : (
						whiteListItem()
					)}
				</MDBox>
			);
		case "delay":
			return <DelayRow label={label} value={value} onChange={onChange} />;
		case "code":
			return (
				<MDBox mt={1}>
					<FormControl fullWidth>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || ""}
							name={path}
							onChange={e => {
								onChange(e.target.value);
							}}
						>
							{codes.map((code, index) => {
								return (
									<MenuItem key={index} value={code.value}>
										{code.label}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</MDBox>
			);
		default:
			return (
				<MDBox mt={1}>
					<MDInput
						type={type}
						className="dialogInput"
						label={label}
						value={value}
						onChange={e => onChange(e.target.value)}
					/>
				</MDBox>
			);
	}
};
/**
 * Component for create a form from a dictionary
 */
export function LittleForm({ object, metadatasSkeleton, handleChange, level = 0, path = null }) {
	let keys = Object.keys(object);
	const dispatch = useDispatch();

	useEffect(() => {
		const onSuccess = res => {
			regexEmail = res.regex;
		};
		const onSuccessApis = res => {
			listOfApis = res?.apisList || [];
		};

		if (regexEmail === "") {
			dispatch(FormActions.getRegexLittleForm("email", onSuccess));
		}
		if (lod_.isEmpty(listOfApis)) {
			dispatch(FormActions.getListApis(onSuccessApis));
		}
	}, []);

	return (
		<div
			style={{
				marginLeft: `${level * 1}rem`
			}}
		>
			{keys.map((key, index) => {
				let item = object[key];
				if (item.type === "level") {
					return (
						<div
							key={index}
							style={{
								border: "1px solid #ccc",
								padding: "1rem",
								marginBottom: "1rem",
								borderRadius: "5px"
							}}
						>
							<h3>{item.label.fr}</h3>
							<LittleForm
								object={item.items}
								level={level + 1}
								metadatasSkeleton={metadatasSkeleton}
								handleChange={handleChange}
								path={path ? `${path}.${key}` : key}
							/>
						</div>
					);
				} else {
					return (
						<MDBox pt={1} key={index}>
							<FormInput
								key={index}
								item={item}
								datas={metadatasSkeleton}
								handleChange={handleChange}
								regexEmail={regexEmail}
								path={path ? `${path}.${key}` : key}
							/>
						</MDBox>
					);
				}
			})}
		</div>
	);
}
/**
 * Create a datas skeleton from a dictionary
 * @param {*} dictionary
 * @param {*} values
 * @returns
 */
export function createDictionarySkeleton(dictionary, values = {}) {
	let metadatas = {};
	let keys = Object.keys(dictionary);

	keys.map(key => {
		let item = dictionary[key];
		if (item.type === "level") {
			metadatas[key] = createDictionarySkeleton(item.items, values[key]);
		} else {
			let defaultValue = values[key] !== undefined ? values[key] : "";
			metadatas[key] = defaultValue;
		}
	});

	return metadatas;
}
