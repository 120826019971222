import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import lod_ from "lodash";
import InputType from "./InputType";

const ChildAccordionArrayMapper = ({
	fieldChild,
	classes,
	values,
	handleRemoveProduct,
	root,
	rootPath,
	PI,
	userLangage,
	customErrors,
	handleInputChangeValidate,
	isEmpty,
	formDictionary,
	handleAddProduct,
	routeDict,
	contextDictionary,
	index,
	title,
	checkInputRequired,
	target,
	listOfRoles,
	listOfRolesAssistant
}) => {
	return (
		<Accordion style={{ margin: "0em 0.5em 1em " }} className="customAccordion" key={index}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{!lod_.isNil(index) && (
					<Box style={{ flex: "0 0 50px" }}>
						<div className={classes.numberCircle}>{index + 1}</div>
					</Box>
				)}

				<Typography>
					<span style={{ fontWeight: "bolder" }}>{title}</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{Object.keys(fieldChild)
					.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
					.map((child, key) => {
						if (
							fieldChild[child].type === "level" &&
							fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							return (
								<>
									{fieldChild[child].value.map((childArray, keyArray) => {
										let rootPathArray = root === "" ? `${root}` : root + `.${child}[${keyArray}]`;
										let rootPathArrayDico =
											rootPath === "" ? `${rootPath}` : rootPath + `.${child}.value[${keyArray}]`;

										return (
											<ChildAccordionArrayMapper
												fieldChild={fieldChild[child].value[keyArray]}
												index={keyArray}
												contextDictionary={contextDictionary}
												isEmpty={isEmpty}
												title={fieldChild[child][userLangage]}
												classes={classes}
												values={values}
												root={rootPathArray}
												handleRemoveProduct={handleRemoveProduct}
												rootPath={rootPathArrayDico}
												PI={PI}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleAddProduct={handleAddProduct}
												handleInputChangeValidate={handleInputChangeValidate}
												checkInputRequired={checkInputRequired}
												routeDict={routeDict}
												target={target}
												listOfRoles={listOfRoles}
												listOfRolesAssistant={listOfRolesAssistant}
											/>
										);
									})}
								</>
							);
						} else if (
							fieldChild[child].type === "level" &&
							!fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							let rootPathArray = root === "" ? `${root}` : root + `.${child}`;
							let rootPathArrayDico = rootPath === "" ? `${rootPath}` : rootPath + `.${child}`;
							return (
								<ChildAccordionArrayMapper
									fieldChild={fieldChild[child]}
									title={fieldChild[child][userLangage]}
									classes={classes}
									isEmpty={isEmpty}
									userLangage={userLangage}
									root={rootPathArray}
									rootPath={rootPathArrayDico}
									values={values}
									keyArray={key}
									PI={PI}
									handleInputChangeValidate={handleInputChangeValidate}
									checkInputRequired={checkInputRequired}
									formDictionary={formDictionary}
									handleRemoveProduct={handleRemoveProduct}
									handleAddProduct={handleAddProduct}
									customErrors={customErrors}
									target={target}
									listOfRoles={listOfRoles}
									listOfRolesAssistant={listOfRolesAssistant}
								></ChildAccordionArrayMapper>
							);
						} else if (
							fieldChild[child].type !== "level" &&
							typeof fieldChild[child] === "object"
						) {
							let rootPathArrayDico = rootPath === "" ? `${child}` : rootPath + `.${child}`;
							let rootPathArray = root === "" ? `${child}` : root + `.${child}`;
							let formConfig = lod_.get(formDictionary, rootPathArrayDico);
							return (
								<Box key={key}>
									<InputType
										regexEmail=""
										listAPIs={[]}
										child={rootPathArray}
										whitelistDynamic={formConfig?.whitelistDynamic}
										isArray={formConfig?.isArray}
										whitelist={formConfig?.whitelist}
										isDisplay={formConfig?.display}
										codes={formConfig?.codes}
										label={fieldChild[child][userLangage]}
										isEdit={fieldChild[child]?.isEdit}
										isRequired={formConfig?.isRequired}
										key={key}
										PI={PI}
										isEmpty={isEmpty}
										type={formConfig?.type}
										valueForm={values}
										value={lod_.get(values, rootPathArray)}
										customErrors={lod_.get(customErrors, rootPathArray)}
										onChange={e => handleInputChangeValidate(e, formConfig, rootPathArray)}
										values={values}
										routeDict={routeDict}
										defaultValueForItem={fieldChild[child]?.defaultValue}
										checkRequired={valueInput => checkInputRequired(valueInput, rootPathArray)}
										target={target}
										listOfRoles={listOfRoles}
										listOfRolesAssistant={listOfRolesAssistant}
									></InputType>
								</Box>
							);
						} else {
							return null;
						}
					})}
			</AccordionDetails>
		</Accordion>
	);
};

export default ChildAccordionArrayMapper;
