/* eslint-disable no-underscore-dangle */

import { CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import i18n from "i18n";

import { useDispatch } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import lod_ from "lodash";
import MDTypography from "components/Basics/MDTypography";

import MDBox from "components/Basics/MDBox";
import InformationCardRole from "../components/InformationCardRole";

/**
 * Step 1: Selected the profile type
 */
const ProfileChoiceStepRole = ({ profile, handleSelectRole, validStep, handleNext }) => {
	const dispatch = useDispatch();

	// Handle select profile
	const selectProfileRole = roleA => {
		handleSelectRole(roleA._id);
		validStep();
		handleNext();
	};

	const [roleList, setRoleList] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (profile?.role) {
			validStep();
		}
		const onSuccess = res => {
			if (!lod_.isEmpty(res.rolesAssistants) && lod_.isArray(res.rolesAssistants)) {
				setRoleList(res.rolesAssistants[0].roles);
			}
			setLoading(false);
		};

		let assistants = [{ assistantID: profile.assistantID, codeOffering: profile.codeOffering }];
		dispatch(FormActions.getRolesAssistantP({ assistants }, onSuccess));
	}, []);

	if (loading) {
		return (
			<MDBox
				display="flex"
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%"
				}}
			>
				<CircularProgress color="info" size={50} />
			</MDBox>
		);
	}

	return (
		<MDBox>
			{roleList?.length > 0 ? (
				<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
					{roleList
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((c, index) => {
							return (
								<InformationCardRole
									element={c}
									onClick={() => selectProfileRole(c)}
									selected={profile && profile.role === c._id}
								/>
							);
						})}
				</MDBox>
			) : (
				<MDBox style={{ display: "flex", justifyContent: "center" }}>
					<MDTypography variant="h5">{i18n.t("SETTINGS.PROFILE.noRole")}</MDTypography>
				</MDBox>
			)}
		</MDBox>
	);
};

export default ProfileChoiceStepRole;
